
/* @font-face {
  font-family: Inter-Bold;
  src: url("./assets/fonts/Inter-Bold.ttf");
}

@font-face {
  font-family: Inter-SemiBold;
  src: url("./assets/fonts/Inter-SemiBold.ttf");
}

@font-face {
  font-family: Inter-Medium;
  src: url("./assets/fonts/Inter-Medium.ttf");
}

@font-face {
  font-family: Inter-Regular;
  src: url("./assets/fonts/Inter-Regular.ttf");
}

@font-face {
  font-family: Inter-Light;
  src: url("./assets/fonts/Inter-Light.ttf");
} */

/* @import '~@fontsource/Lexend/300.css';
@import '~@fontsource/Lexend/400.css';
@import '~@fontsource/Lexend/500.css';
@import '~@fontsource/Lexend/600.css';
@import '~@fontsource/Lexend/700.css';
@import '~@fontsource/Lexend/800.css'; */


body {
  margin: 0;
  font-family: "Lexend", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  /* font-family: -apple-system, BlinkMacSystemFont, MyriadPro_Light, MyriadPro_Bold,
    MyriadPro_Medium, MyriadPro_Regular, sans-serif, Inter-Bold, Inter-SemiBold, Inter-Medium, Inter-Regular, Inter-Light; */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

/* .MuiTableRow-root td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top: 1px solid #3E4250;
  border-left: 1px solid #3E4250;
  border-bottom: 1px solid #3E4250;
}



.MuiTableRow-head th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid #3E4250;
  border-right: 1px solid #3E4250;
  border-bottom: 1px solid #3E4250;
} */::-webkit-scrollbar{
  width: 15px;
}

::-webkit-scrollbar-thumb{
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #c3c4c7;
}